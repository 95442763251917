export default {
  methods: {
    async handlePutPersonResponsible() {
      this.isLoading.save = true;

      const response = await this.fetchPutPersonResponsible({
        bse_name: this.value.bse,
        agent_name: this.value.bd,
        account_support_name: this.value.as,
        supervisor_name: this.value.hospitality,
      });

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        this.$toast.show('Perubahan berhasil disimpan.');
        this.$router.push(`/property-detail/${this.propertyId}/overview`);
      }

      this.isLoading.save = false;
    },

    async handleGetPersonResponsible() {
      const response = await this.fetchGetPersonResponsible();

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        const mappingData = datas =>
          !datas ? [] : datas.map(data => ({ label: data, val: data }));

        const { data } = response.data;

        this.list.bse = mappingData(data.bse_name);
        this.list.bd = mappingData(data.agent_name);
        this.list.as = mappingData(data.account_support_name);
        this.list.hospitality = mappingData(data.supervisor_name);
      }
    },

    async handleGetSinggahsiniInfo() {
      const response = await this.fetchGetSinggahsiniInfo();

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        const { handler } = response.data.data;

        this.value.bse = handler.bse_name;
        this.value.bd = handler.agent_name;
        this.value.as = handler.account_support_name;
        this.value.hospitality = handler.supervisor_name;
      }
    },
  },
};
