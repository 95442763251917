import api from '@admin/api/service';

export default {
  methods: {
    async fetchPutPersonResponsible(params) {
      let response = {};

      try {
        response = await api.put(`/singgahsini/api/property/${this.propertyId}/handler`,
          params
        );
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },

    async fetchGetPersonResponsible() {
      let response = {};

      try {
        response = await api.get('/singgahsini/api/property/handler');
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },

    async fetchGetSinggahsiniInfo() {
      let response = {};

      try {
        response = await api.get(
          `/singgahsini/api/property/${this.propertyId}/singgahsini_info`
        );
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },
  },
};
