<template>
  <div class="form-person-responsible">
    <header>
      <bg-skeleton
        v-if="isLoadingPropertyDetail"
        width="300px"
        height="40px"
        class="mb-32"
      />
      <bg-text v-else size="heading-2" class="mb-32">
        {{ xPropertyDetail.name }}
      </bg-text>
      <bg-text size="heading-4" class="mb-32">Penanggung Jawab</bg-text>
    </header>

    <bg-grid>
      <bg-grid-item :col="6">
        <bg-field label="BSE">
          <bg-skeleton v-if="isLoading.init" width="100%" :height="50" />
          <bg-select v-else v-model="value.bse" :options="list.bse" />
        </bg-field>
      </bg-grid-item>
      <bg-grid-item :col="6">
        <bg-field label="BD">
          <bg-skeleton v-if="isLoading.init" width="100%" :height="50" />
          <bg-select v-else v-model="value.bd" :options="list.bd" />
        </bg-field>
      </bg-grid-item>
      <bg-grid-item :col="6">
        <bg-field label="AS">
          <bg-skeleton v-if="isLoading.init" width="100%" :height="50" />
          <bg-select v-else v-model="value.as" :options="list.as" />
        </bg-field>
      </bg-grid-item>
      <bg-grid-item :col="6">
        <bg-field label="hospitality">
          <bg-skeleton v-if="isLoading.init" width="100%" :height="50" />
          <bg-select
            v-else
            v-model="value.hospitality"
            :options="list.hospitality"
          />
        </bg-field>
      </bg-grid-item>
    </bg-grid>

    <bg-grid class="mt-32">
      <bg-grid-item :col="12">
        <bg-button
          v-if="!isLoading.init"
          variant="primary"
          @click="isConfirmationModalOpen = true"
        >
          Simpan
        </bg-button>
      </bg-grid-item>
    </bg-grid>

    <bg-modal
      v-model="isConfirmationModalOpen"
      :close-on-click-backdrop="false"
      desktop-size="sm"
      title="Yakin menyimpan perubahan?"
      description="Silakan klik simpan untuk menyimpan perubahan."
    >
      <template v-slot:footer>
        <div class="flex align-center justify-end">
          <bg-button
            class="mr-16"
            @click="isConfirmationModalOpen = false"
            :disabled="isLoading.save"
          >
            Batal
          </bg-button>
          <bg-button
            @click="handlePutPersonResponsible"
            variant="primary"
            :loading="isLoading.save"
          >
            Simpan
          </bg-button>
        </div>
      </template>
    </bg-modal>
  </div>
</template>

<script>
import {
  BgField,
  BgSelect,
  BgGrid,
  BgGridItem,
  BgSkeleton,
  BgText,
  BgButton,
  BgModal,
} from 'bangul-vue';

import { mapState, mapActions, mapMutations } from 'vuex';
import { LOADING_STATE } from '@admin_store/modules/propertyDetail';

import fetchApi from './mixins/fetchApi.js';
import handleApi from './mixins/handleApi.js';

export default {
  name: 'FormPersonResponsible',

  components: {
    BgField,
    BgSelect,
    BgGrid,
    BgGridItem,
    BgSkeleton,
    BgText,
    BgButton,
    BgModal,
  },

  mixins: [fetchApi, handleApi],

  data() {
    return {
      isLoading: {
        init: false,
        save: false,
      },

      isConfirmationModalOpen: false,

      value: {
        bse: '',
        bd: '',
        as: '',
        hospitality: '',
      },

      list: {
        bse: [],
        bd: [],
        as: [],
        hospitality: [],
      },
    };
  },

  computed: {
    ...mapState('propertyDetail', ['xLoadingState', 'xPropertyDetail']),
    isLoadingPropertyDetail() {
      return this.xLoadingState === LOADING_STATE.FETCHING;
    },
    propertyId() {
      return this.$route.params.propertyId;
    },
  },

  created() {
    this.initPage();
  },

  methods: {
    ...mapActions('propertyDetail', ['xConsumePropertyDetail']),
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),

    async initPage() {
      if (!this.xPropertyDetail.name) {
        await this.xConsumePropertyDetail(this.propertyId);
      }
      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.xPropertyDetail.name,
          url: `/property-detail/${this.propertyId}/overview`,
        },
      });

      this.isLoading.init = true;
      await this.handleGetPersonResponsible();
      await this.handleGetSinggahsiniInfo();
      this.isLoading.init = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./FormPersonResponsible.scss" />
